.main-content-wrapper {
  position: relative;
  padding: 2rem;
}

.page-wrapper {
  margin-top: 106px;

  @media (max-width: 600px) {
    margin-top: 70px;
  }

  @media (max-width: 900px) {
    margin-top: 78px;
  }

  margin-bottom: 40px;
}

$breakpoint-mobile: 430px;

@media (max-width: $breakpoint-mobile) {
  .main-content-wrapper {
    padding: 2rem 1rem !important;
  }
  .class-card-container {
    min-width: 100% !important;
  }
}
// .profile-menu {
//   position: absolute;
//   top: 15px;
//   left: 15px;
//   z-index: 10;
// }

// .source-info {
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   z-index: 10;
// }

// .video-player-container {
//   flex: 1;
//   position: relative;
// }

// .video-player-wrapper {
//   position: absolute;
//   width: 100%;
//   bottom: 0;
//   padding: 10px;
// }

// .bottom-navigation-bar {
//   width: 100%;
// }

// .subpage-container {
//   position: relative;
//   width: 100%;
//   height: 100%;
// }

// .video-actions {
//   position: absolute;
//   bottom: 60px;
//   right: 10px;
//   z-index: 10;
// }
