.auth-page-wrapper {
  width: 100%;
  height: 100%;
  display: flex;

  .auth-background {
    height: 100%;
    background: linear-gradient(90deg, #4956a2 0%, #0e4097 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    .auth-left-logo {
      top: 20px;
      left: 20px;
      width: 240px;
      position: absolute;
    }

    .auth-left {
      color: #ffffff;
    }
    .auth-left--title-b {
      font-weight: 500;
    }
    .auth-left--title-s {
      margin-top: 20px;
    }
  }

  .auth-app--logo {
    display: none;
  }
}

$breakpoint-tablet: 1024px;
@media (max-width: $breakpoint-tablet) {
  .auth-background {
    display: none !important;
  }
  .auth-app--logo {
    display: flex !important;
    align-items: center;
    justify-content: center;
    img {
      width: 240px;
      margin-bottom: 2rem;
    }
  }
}

.btn-google-auth {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.75rem 1.5rem;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.25rem;
  outline: 0;
  border: 0;

  svg {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
  }
}

.auth-header {
  padding: 16px 0px;
  position: relative;
  &-bar {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 2rem;
      margin-bottom: 10px;
    }
    .signup-link {
      display: flex;
      align-items: center;
      a {
        text-decoration: none;
        color: rgba(0, 0, 0, 0.87);
        margin-left: 4px;
      }
    }
  }
  .divider-line {
    border-top: 1px solid #606060;
  }
}

.auth-header-title {
  font-weight: 700 !important;
  color: #3f51b5;
}

.auth-link {
  color: #606060;
}

.btn-auth {
  border-radius: 0.5rem;
  padding: 0.55rem 1rem;
  text-align: center;
  outline: 0;
  border: 0;
  color: #efefef;
  background-color: #3f51b5;
  font-size: 1.2rem;
  font-weight: 800;
}

.auth-reset-password {
  padding: 60px 50px !important;
  border-radius: 1.5rem;
  background-color: #efefef;
  h5,
  p {
    text-align: center;
    margin-bottom: 30px;
  }
}

.auth-signup-password {
  > div {
    width: 48%;
  }
}

.text-card-4line-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
